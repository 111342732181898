@import url('https://rsms.me/inter/inter.css');

html,
body,
#root,
.bg,
canvas {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  background: #171717;
  font-family: 'Inter var', sans-serif;
}

.bg {
  position: absolute;
  background: radial-gradient(at 50% 100%, #691565 0%, #272730 40%, #171720 80%, #070710 100%);
}

h1 {
  position: absolute;
  top: 120px;
  left: 60px;
  font-weight: 900;
  font-size: 5em;
  margin: 0;
  color: white;
  opacity: 0.4;
  line-height: 0.6em;
  letter-spacing: -2px;
  text-align: left;
}

h1 > span, h1 > a {
  white-space: pre;
}

.layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(63, 12, 88, 0.25) 0%, transparent 100%);
  pointer-events: none;
}

span.header {
  font-weight: 700;
  position: absolute;
  display: inline-block;
  width: 500px;
  transform: translate3d(0, -50%, 0);
  font-size: 9em;
  line-height: 0.9em;
  pointer-events: none;
  top: 350px;
  left: 50px;
}

span.header-left {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  position: absolute;
  display: inline-block;
  transform: translate3d(0, -50%, 0);
  line-height: 1em;
  top: 200px;
  left: 60px;
  font-size: 4em;
  width: 200px;
}

a {
  font-weight: 400;
  font-size: 14px;
  color: inherit;
  position: absolute;
  display: inline;
  text-decoration: none;
  z-index: 1;
  color: white;
  opacity: 0.4;
}

a.sub-header {
  top: 280px;
  left: 65px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
  font-size: 1em;
}

a.top-left {
  top: 60px;
  left: 60px;
}

a.top-right {
  top: 60px;
  right: 60px;
}

a.bottom-left {
  bottom: 60px;
  left: 60px;
}

a.bottom-right {
  bottom: 60px;
  right: 60px;
  opacity: 0.3;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 3em;
  }
}